
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import { getSignLogList } from "@/api/request/user";
import Pagination from "@/comp/Pagination/index.vue";

//组件
@Component({
  name: "SignAwardLog",
  components: {
    Pagination,
  },
})

//函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数

    //临时数据
    day_str: "", //第几天
    user_id_str: "", //用户ID
    cycle_str: "", //签到周期

    //接口数据
    day: 1, //第几天
    date: "", //日期
    cycle: 1, //签到周期
    user_id: 0, //用户ID
    country: "",
    nickname: "", //用户昵称
  };

  //创建时调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.day = Number(this.listQuery.day_str);
    this.listQuery.cycle = Number(this.listQuery.cycle_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await getSignLogList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //获取奖励列表
  private getGiftList(giftList: any): string {
    //定义变量
    var giftStr: string = "";

    //数据赋值
    if (giftList) {
      for (var i: number = 0; i < giftList.length; i++) {
        giftStr += giftList[i].prop_id + ":" + giftList[i].prop_name + "(" + giftList[i].prop_num + ") | ";
      }
      if (giftStr.length > 0) giftStr = giftStr.substring(0, giftStr.length - 3);
    }

    //返回
    return giftStr;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
